import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/anasayfa'
  },
  {
    path: '/login',
    component: () => import('@/components/LoginPage.vue')
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: 'anasayfa',
        component: () => import('@/views/DashboardPage.vue')
      },
      {
        path: 'campaign-list',
        component: () => import('@/views/CampaignListNavigationPage.vue')
      },
      {
        path: 'profil',
        component: () => import('@/views/MyAccountPage.vue')
      },
      {
        path: 'linklerim',
        component: () => import('@/views/LinkListingPage.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
