import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/display.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/typography.css'

/* Core CSS required for Ionic components to work properly */

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"

/* Theme variables */
import './theme/variables.css';


import App from './App.vue'
import { IonicVue } from '@ionic/vue'
import axios from 'axios'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import { storage } from './storage'


const app = createApp(App)
  .use(IonicVue)
  .use(createPinia())
  .use(router);


router.isReady().then(() => {
  app.mount('#app')
});

axios.interceptors.request.use(async function (config) {
  const credentials = await storage.get('credentials')
  if ((!credentials?.username || !credentials?.password)) {
    router.push('/login')
  }
  return config
}, function (error) {
  storage.set('credentials', {})
  return Promise.reject(error)
});
