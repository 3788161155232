
import { IonIcon, IonLabel, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/vue'
import { home, link, person, pricetags,star,wallet } from 'ionicons/icons'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      pricetags, 
      star,
      wallet,
      home,
      link,
      person
    }
  }
});
